
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import { Detail } from "@/models/detail.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import DetailForm from "@/components/Layout/Backoffice/DetailForm.vue";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, DetailForm, BarSearch, DotsMenu },
})
export default class Details extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  StyleCheck
) {
  $refs!: {
    detailForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultDetail: Detail = {
    id: undefined,
    name: "",
    type: "",
    status: {
      name: "",
    },
  };
  detail: Detail = { ...this.defaultDetail };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async searchDetailByText() {
    this.loadingDT = true;

    await this.getDetails(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getDetails(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getDetails(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("details/getDetails", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Details.fetchError.details"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getDetails(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get detail_types(): { key: string; value: string }[] {
    const types: { key: string; value: string }[] = [];
    let keys = Object.keys(this.$constants.DETAIL_TYPES);
    for (const key of keys) {
      types.push({ key: key, value: this.$constants.DETAIL_TYPES[key] });
    }
    return types;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Details.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Details.headers.type"),
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Details.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      ...(this.$perms.check({
        actions: ["D", "U"],
        entities: [this.$constants.PRIVILEGES.DETAIL],
      })
        ? [
            {
              text: this.$tc("Details.headers.actions"),
              value: "actions",
              sortable: false,
              align: "center",
            },
          ]
        : []),
    ];
  }

  private get details(): Detail[] {
    let response = this.$store.getters["details/getDetails"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.details) {
      return response.details;
    } else {
      return [];
    }
  }

  private async editDetail(detail: Detail) {
    this.detail = { ...detail };
    this.detail.status = { ...detail.status } as Status;
    this.dialog = true;
  }

  private async deleteDetailConfirm(detail: Detail) {
    this.detail = { ...detail };
    this.dialogDelete = true;
  }

  private async deleteDetail() {
    this.loading = true;
    await this.$store
      .dispatch("details/updateDetailStatus", {
        id: this.detail.id as number,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Details.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getDetails(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.loading = false;
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Details.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);

        this.loading = false;
      });
  }

  private close() {
    this.dialog = false;
    this.detail = Object.assign(this.detail, this.defaultDetail);
    this.getDetails(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.detail = Object.assign(this.detail, this.defaultDetail);
  }
}
